<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :height="height"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>

        <el-table-column label="广告名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.commercialName }}</template>
        </el-table-column>
        <el-table-column label="广告标题" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.commercialTitle
          }}</template>
        </el-table-column>
        <el-table-column label="广告logo" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.commercialLogo"
              :preview-src-list="[scope.row.commercialLogo]"
            >
            </el-image>
            <!-- {{ scope.row.commercialLogo }} -->
          </template>
        </el-table-column>
        <el-table-column label="广告状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: red" v-if="scope.row.commercialstatus == 0"
              >未启用</span
            >
            <span style="color: green" v-if="scope.row.commercialstatus == 1"
              >启用</span
            >

            <!-- {{
            scope.row.commercialstatus
          }} -->
          </template>
        </el-table-column>
        <el-table-column
          label="广告展示次数"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.showcount }}</template>
        </el-table-column>
        <el-table-column
          label="广告打开次数"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.opencount }}</template>
        </el-table-column>
        <el-table-column label="广告内容" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.content }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.commercialTime }}</template>
        </el-table-column>
        <el-table-column
          label="创建人名称"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.memberName }}</template>
        </el-table-column>

        <el-table-column label="操作" width="170" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDelte(scope.row.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="labelType == 'add' ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="800px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="广告名称：" required prop="commercialName">
              <el-input
                v-model="form.commercialName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="广告标题：" required prop="commercialTitle">
              <el-input
                v-model="form.commercialTitle"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="广告logo：" required>
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="commercialLogo"
                  :src="commercialLogo"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="广告状态">
              <el-switch
                v-model="form.commercialstatus"
                active-color="#13ce66"
                inactive-color="#ccc"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelType: "add",
      rules: {
        commercialTitle: [
          { required: true, message: "广告标题不能为空", trigger: "blur" },
        ],
        commercialName: [
          { required: true, message: "广告名称不能为空", trigger: "blur" },
        ],
      },
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {
        commercialstatus: 0,
      },
      commercialLogo: "", //logo
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
    };
  },

  created() {
    this.getList();
  },

  computed: {
    switchValue: {
      get() {
        return this.form.commercialstatus === 1;
      },
      set(val) {
        this.form.commercialstatus = val ? 1 : 0;
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/commercial/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },

    // 保存
    handleSave() {
      var that = this;
      this.$refs.form.validate((ok) => {
        if (ok) {
          if (!this.commercialLogo) {
            return this.$message.error("请上传广告logo");
          }

          that.form.commercialLogo = that.commercialLogo;

          that.$http
            .post("/commercial/save", that.form)
            .then(function (response) {
              if (response.data.code == 200) {
                that.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true,
                });
                that.showDialog = false;
                that.getList();
              } else {
                that.$notify.info({
                  title: "提示",
                  message: response.data.message,
                  showClose: true,
                });
              }
            })
            .catch(function (error) {});
        } else {
          console.log(111);
        }
      });
    },

    //修改
    edit(row) {
      console.log(row, 302);
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
      this.commercialLogo = this.form.commercialLogo;
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/commercial/delete", { id: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    handleAvatarSuccess(res, file) {
      this.commercialLogo = res.data.url;
      console.log(this.form.commercialLogo, res.data.url, 341);
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith("image/");

      if (!isImage) {
        this.$message.error("上传头像图片只能是图片格式!");
      }
      return isImage;
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #ccc;
}
.avatar {
  width: 130px;
  height: 130px;
  display: block;
}
</style>
